@font-face {
	font-family: 'Poppins Regular';
	src: url(/assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
	font-family: 'Poppins Bold';
	src: url(/assets/fonts/Poppins/Poppins-Bold.ttf);
}

@font-face {
	font-family: 'Poppins SemiBold';
	src: url(/assets/fonts/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
	font-family: 'Poppins Light';
	src: url(/assets/fonts/Poppins/Poppins-Light.ttf);
	font-style: italic;
}

@font-face {
	font-display: swap;
	font-family: 'Oswald Regular';
	src: url(/assets/fonts/Oswald/Oswald-Regular.ttf);
}

@font-face {
	font-family: 'Oswald Bold';
	src: url(/assets/fonts/Oswald/Oswald-Bold.ttf);
}

@font-face {
	font-family: 'Oswald SemiBold';
	src: url(/assets/fonts/Oswald/Oswald-SemiBold.ttf);
}

@font-face {
	font-family: 'Oswald Light';
	src: url(/assets/fonts/Oswald/Oswald-Light.ttf);
}

@font-face {
	font-display: swap;
	font-family: 'Trebuchet regular';
	src: url(/assets/fonts/trebuchet-ms/trebuc.ttf);
}
