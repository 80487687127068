html {
	scroll-behavior: smooth;
}
.decrease_increase {
	width: 30px;
	height: 30px;
	background-color: #e2e8f0;
	display: flex;
	align-items: center;
	justify-content: center;
	/* border-color: #8a8a8b;
	border-width: 1px; */
}
.size {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}
select.language {
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: url('/assets/img/select.svg');
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 50%;
	border-radius: 2px;
	padding: 2rem;
	padding-right: 1rem;
}

.below-line {
	position: relative;
	z-index: 100;
}

.above-line {
	position: relative;
	/* z-index: 3; */
}

.vertical-line {
	border-right-width: 0.1px;
	border-right-color: #4f556111;
	position: absolute;
	height: 100%;
	z-index: 2;
}

.vertical-line#line1 {
	left: 0;
}
.vertical-line#line2 {
	left: 25%;
}

.vertical-line#line3 {
	left: 50%;
}

.vertical-line#line4 {
	right: 25%;
}

.vertical-line#line5 {
	right: 0;
}

/* Sliders */
.tour-tile,
.tour-tile > * {
	cursor: pointer;
	transition: 0.3s all ease-in-out;
}

.tour-tile > div:not(.dont-hide) {
	opacity: 0;
}

.tour-tile:hover > img {
	transform: scale(1.1);
}
/* .top-tours-title {
	margin: -10px;
} */
.tour-tile:hover > .top-tours-title,
.activities-card {
	opacity: 1;

	/* transform: scale(1.05); */
}

.tour-tile:hover {
	display: block;
}
.nav-bottom-margin {
	margin-bottom: 3.75rem;
}

.max-width {
	max-width: 25rem;
}

.a-border-bottom {
	border-bottom: 3px solid;
	border-color: rgba(254, 173, 74, 0);
}

.a-border-bottom:hover {
	border-color: #feae4a;
}

.custom-input::placeholder {
	font-family: 'Poppins Regular';
	font-size: 16px;
	color: #4f5761;
	opacity: 1;
}
.search-bar-text-color {
	color: red;
}
.text-1rem {
	font-size: 1em;
}

.max-price-width {
	width: intrinsic; /* Safari/WebKit uses a non-standard name */
	width: -moz-max-content; /* Firefox/Gecko */
	width: -webkit-max-content; /* Chrome */
	width: max-content;
}

/* Filter */
/* The container */
.filter-checkbox-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.filter-checkbox-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #ecece9;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* footer-bottom */

.footer-bottom {
	background-color: #525864;
	/* height: 113px; */
}
.country-slide-desc > * {
	padding-right: 1rem;
}

/* tour landing */

.tour-day-circle {
	margin-left: -1.25rem;
	z-index: 2;
	position: relative;
}
.tour-contact {
	margin-left: -2.7rem;
}

.leaflet-container {
	width: 100%;
	height: 513px;
}
.zoom {
	transition: transform 0.5s;
}
.zoom1 {
	-ms-transform: scale(1.5); /* IE 9 */
	-webkit-transform: scale(1.5); /* Safari 3-8 */
	transform: scale(1.5);
}
.zoom2 {
	-ms-transform: scale(1); /* IE 9 */
	-webkit-transform: scale(1); /* Safari 3-8 */
	transform: scale(1);
}

.transport-cube {
	background-color: #525864;
}

.filter {
	font-size: 40px;
	line-height: 30px;
}

@media only screen and (max-width: 400px) {
	.filter {
		font-size: 30px;
	}
	.text-3xl {
		font-size: 20px;
	}
	.filter-checkbox-container {
		font-size: 13px;
	}
}

@media only screen and (max-width: 767px) {
	.contact-responsive {
		margin-top: 5%;
		margin-left: 30%;
		text-align: left;
		display: block !important;
	}
	.contact-footer {
		margin-right: 100%;
		width: 40vh;
	}
	.footer-text {
		display: flex;
		justify-content: center !important;
		flex-direction: column;
		min-width: 320px;
		width: 100%;
	}
	.send {
		margin-right: 100%;
		width: 40vh;
	}
	.tour-duration-table {
		margin-top: 30px;
	}
	.tags {
		margin-right: 30%;
	}
	.hide {
		display: block;
    margin-top: 10px;
	}

  .footer-text-h3 {
    text-align: center !important;
    padding-right: 0 !important;
  }

	.last-update {
		/* margin-bottom: 50px !important; */
		/* text-align: center !important; */
		margin-top: 50px;
		margin-right: 80%;
		display: block !important;
	}
}

.about-country > p > span {
	font-family: 'Oswald Regular' !important;
	font-size: 40px !important;
}
.slider_image {
	height: 700px;
}
.slick-dots {
	bottom: -57px !important;
	display: flex !important;
	justify-content: flex-end !important;
}
.slick-dots li,
.slick-dots li button {
	width: 12px !important;
	height: 12px !important;
	cursor: pointer !important;
	margin: 0 34px 0 0 !important;
}
.slick-dots li:before {
	font-family: slick;
	font-size: 6px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	content: '';
	text-align: center;
	opacity: 0.25;
	color: #000;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: scale(0);
	transition: transform 150ms ease-in-out;
}
.slick-dots li:before {
	content: '';
	transform: scale(0);
	transition: transform 150ms ease-in-out !important;
	width: 40px !important;
	height: 40px !important;
	background-color: #feae4a;
	position: absolute;
	left: 50% !important;
	top: 50% !important;
	display: block !important;
	border-radius: 50% !important;
	opacity: 1 !important;
	margin-left: -20px !important;
	margin-top: -20px !important;
}
.slick-active:before {
	transform: scale(1) !important;
}
.slick-dots li button:before {
	content: none !important;
}
.slick-dots li button {
	font-size: 0 !important;
	line-height: 0 !important;
	display: block !important;
	padding: 5px !important;
	color: transparent !important;
	border: 0 !important;
	outline: none !important;
	background: transparent !important;
	width: 12px !important;
	height: 12px !important;
	background-color: #515660 !important;
	border-radius: 50% !important;
	position: relative !important;
	z-index: 2 !important;
}
.slick-dots li,
.slick-dots li button {
	width: 12px;
	/* height: 40px; */
	cursor: pointer;
	margin: 0 34px 0 0;
}

.slick-active button {
	background-color: black;
}
.left-arrow.down {
	transform: rotate(90deg);
}

.left-arrow {
	line-height: 1rem;
	margin-left: 10px;
	transition: 0.3s ease-in-out;
	display: inline-block;
	transform: translate(-50%);
}
.collapse.opened {
	visibility: visible;
	max-height: 500px;
	height: unset;
}
.collapse {
	overflow: hidden;
	visibility: hidden;
	max-height: 0;
	height: 0;
	transition: max-height 0.7s ease-in-out;
}
.projects-content table tr:first-child {
	color: #65c8ef;
	line-height: 4.5rem;
}

.projects-content table tr:last-child {
	line-height: 4rem;
	/* text-transform: uppercase; */
	/* font-family: 'Oswald SemiBold'; */
}

.projects-content table tr {
	/* line-height: 2.8rem; */
	font-size: 15px;
	text-align: left;
	/* font-family: 'Oswald Regular'; */
}
.projects-content {
	font-family: 'Trebuchet regular';
}
.search-input {
	color: #4f5761;
}

.burger-lang {
	color: #fff;
	text-align: end;
	margin-right: 20px;
	cursor: pointer;
}

@media only screen and (max-width: 1032px) {
	.nav-bar {
		display: none;
	}
}
@media only screen and (max-width: 577px) {
	.responsive-text {
		max-height: 100px;
	}
}

.sub-menu-ul {
	position: absolute;
	padding-right: 30px;
	padding-left: 20px;
	display: none;
	z-index: 100;
	background-color: #fefcf9;
	box-shadow: 0 6px 8px 0 rgba(0, 0, 0, -0.8), 0 6px 11px 0 rgba(0, 0, 0, 0.07);
	margin-top: -30px;
}
.sub-menu-ul > li {
	padding-top: 10px;
	padding-bottom: 5px;
}
.sub-menu-ul > li > a {
	padding-top: 0rem;
	padding-bottom: 0rem;
	padding-left: 0rem;
	padding-right: 0rem;
}
.nav-li {
	height: inherit;
}

.nav-li:hover .sub-menu-ul {
	display: block;
}
.sub-menu-ul:hover {
	display: block;
}
.about1 {
	z-index: 100;
}

.regional-tours-ul {
	display: none;
	position: absolute;
	left: 175px;
	min-width: 200px;
	box-shadow: 0 6px 8px 0 rgba(0, 0, 0, -0.8), 0 6px 11px 0 rgba(0, 0, 0, 0.07) !important;
	height: 230px;
}

.tours-parent:hover > .regional-tours-ul {
	display: block;
}

.regional-tours-li {
	position: relative;
	top: -35px;
	left: 0;
	background-color: #fefcf9;
	width: 100%;
	display: inline-block;
	padding-right: 10px;
	padding-left: 5px;
}
