.search-select__control {
	background-color: transparent;
	display: flex;
	height: 100%;
}
.search-select__menu-list {
	margin: 0;
	padding: 0;
	margin-top: 2px;
}
.search-select__menu {
	margin: 0;
	background-color: inherit;
}
.search-select__option:hover {
	background-color: #65c8ef;
}
.search-select__option {
	padding: 1rem 2rem;
	cursor: pointer;
}
.search-select__indicator-separator {
	display: none;
}
.search-select__option:not(:last-child) {
	border-bottom: 1px solid #0000001c;
}
.search-select__indicator {
	color: inherit !important;
}
